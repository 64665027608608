if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

var urlComponents = document.location.origin.split("/")[2].split(".");
var subDomain = urlComponents[0]
var tld = urlComponents[2] == 'undefined' || urlComponents[2] == null || urlComponents[2] == '' ? urlComponents[1] : urlComponents[2];
var siteUrl = (subDomain.length > 0 && subDomain.toLowerCase() != "optitrack" ? subDomain + "." : "") + "optitrack." + tld;
var overrideSiteUrl = "1";

function setContinueShoppingLink_Orig() {
    var shopUrl = window.location.href;
    var buttonObj = document.getElementById("continueShopping");

    shopUrl = shopUrl.split("?")[1];

    if (shopUrl != 'undefined' && shopUrl != '' && shopUrl != null && shopUrl.toLowerCase() != 'shopurl=') {
        shopUrl = shopUrl.split("=")[1];

        if (buttonObj != 'undefined' && buttonObj != null) {
            buttonObj.href = shopUrl;
        }

        $("input[type=hidden][name=shopurl]").each(function () {
            $(this).val(shopUrl);
        });
    }
    else {
        //If there is no shop URL don'trisk a 404 instead send it to the index page.
        shopUrl = "/";
        if (buttonObj != 'undefined' && buttonObj != null) {
            buttonObj.href = shopUrl;
        }

        $("input[type=hidden][name=shopurl]").each(function () {
            $(this).val(shopUrl);
        });
    }
}

$(document).ready(function () {
    initOverrideUrl();
});

function setContinueShoppingLink() {
    try {
        var conButton = $("a#continueShopping");
        var last = localStorage.getItem("last_product_url") || "/";
        if (conButton.length > 0) {
            $(conButton).attr("href", last);
            return true;
        }
        else {
            localStorage.setItem("last_product_url", last);
            for (var i = 0; i < document.forms.length; i++) {
                if (document.forms[i].action.indexOf("/cart/store/adjustcart.cgi") > 0) {
                    localStorage.setItem("last_product_url", window.location.href);
                    return true;
                }
            }
        }
        return false;
    } catch (error) {
        // trhis is probably because local storage does not exist
        return false;
    }
}

function initOverrideUrl() {

    $.get("/api/optitrackAPI.cgi?cmd=getUserIp",
        {},
        function (data) {
            $.cookie("userIp", data.ip);
            if (data.isin == "1") {
                if ($("#loadIntoAdmin").length <= 0 && $("#continueShopping").length >= 1 && $("#isEmptyCart").length <= 0) {
                    window.location.reload();
                }
            }
        }
    );

    $.cookie("override", "1");
    $.cookie("overrideURL", siteUrl);

    if ($("#overrideURL").length != 0) {
        $("#overrideURL").val(siteUrl);
        $("#override").val("1");
    }

    //Ensure there are no remnants let before adding new elements
    $("input[name=override]").remove();
    $("input[name=overrideURL]").remove();

    //Enable the override flag so accessory_check.cgi knows what to do.
    $("a[href='/cart/optitrack/accessory_check.cgi']").attr("href", "/cart/optitrack/accessory_check.cgi?override=1&overrideURL=" + siteUrl);
    // $("a[id='continueShopping']").attr("href","/cart/optitrack/adjustcart.cgi?override=1&overrideURL="+siteUrl);
    $(".add").append('<input type="hidden" name="override" value="1">');
    $(".add").append('<input type="hidden" name="overrideURL" value="' + siteUrl + '">');
    $(".add-to-cart-form").append('<input type="hidden" name="override" value="1">');
    $(".add-to-cart-form").append('<input type="hidden" name="overrideURL" value="' + siteUrl + '">');
    $(".remove").append('<input type="hidden" name="override" value="1">');
    $(".remove").append('<input type="hidden" name="overrideURL" value="' + siteUrl + '">');
    $(".update").append('<input type="hidden" name="override" value="1">');
    $(".update").append('<input type="hidden" name="overrideURL" value="' + siteUrl + '">');

    $("#compatibleSoftware").addClass("support compatibility columns");
    var productType = $("#support-product-description h1").text();

    if (productType.indexOf("from") >= 0) {
        productType = $.trim(productType.split("from")[0]);
    }
    else if (productType.indexOf("for") >= 0) {
        productType = $.trim(productType.split("for")[0]);
    }
    else {
        productType = $.trim(productType.split("(retired)")[0]);
    }

    if ($("#new-support-product-finder").length > 0) {
        generateCompatibilityTable(productType);
    }
}



var globVar = "";
function generateCompatibilityTable(productId) {
    var productIdClosure = productId;
    $.get(
        "/api/optitrackAPI.cgi",
        { "cmd": "fetchSoftwareCompatibilityMatrix" },
        function (data) {
            globVar = data;
            $("#compatibleSoftware").html("");

            var newTable = $("<table>");
            var newThead = $("<thead>");
            var newTbody = $("<tbody>");
            var newTr = "";
            var newTd = "";
            var newTh = "";
            var doAddCategory = false;
            var categoryLength = 0;

            newTr = $("<tr>");

            for (k in data.categories) {
                if (productIdClosure != '' && productIdClosure != 'undefined' && productIdClosure != null) {
                    if (data.categories[k].onlyShowWhenSpecified && $.inArray(data.categories[k].title, Object.keys(data.matrix[productIdClosure])) >= 1) {
                        doAddCategory = true;
                    }
                    else {
                        if (data.categories[k].onlyShowWhenSpecified) {
                            doAddCategory = false;
                        }
                        else {
                            doAddCategory = true;
                        }
                    }
                }
                else {
                    doAddCategory = true;
                }

                if (doAddCategory) {
                    categoryLength++;
                    newTh = $('<th class="compatibleSoftwareHeader">');
                    newTh.html(data.categories[k].title + (data.categories[k].retired ? '<br><span class="retired">Retired</span>' : ""));
                    newTr.append(newTh);
                }
            }

            newThead.append(newTr);

            if (productIdClosure != '' && productIdClosure != 'undefined' && productIdClosure != null) {
                newTr = generateRowForProduct(data, productIdClosure, categoryLength);
                newTbody.append(newTr);
            }
            else {
                for (var k in data.matrix) {
                    newTr = generateRowForProduct(data, k, categoryLength);
                    newTbody.append(newTr);
                }
            }
            newTable.append(newThead);
            newTable.append(newTbody);
            $("#compatibleSoftware").append(newTable);
        }
    );
}

function generateRowForProduct(data, productId, categoryLength) {
    var newTr = $('<tr class="compatibleSoftwareRow">');
    var newTd = "";

    for (var sKey in data.matrix[productId]) {
        if (sKey.toLowerCase() != 'othername') {
            for (var i = 0; i < data.matrix[productId][sKey].length; i++) {
                if (data.matrix[productId][sKey][i].toLowerCase() != 'othername') {
                    newTd = $("<td>");
                    newTd.html(data.matrix[productId][sKey][i]);
                    newTr.append(newTd);
                }
            }

            //Handle Cases Where This Category Has No Value.
            if (data.matrix[productId][sKey].length <= 0) {
                newTd = $("<td>");
                newTr.append(newTd);
            }
        }

    }
    //globVar = data.matrix[productId];
    var productKeyLen = Object.keys(data.matrix[productId]).length;

    if ((productKeyLen - 1) < categoryLength) {
        for (j = i; j < i + (categoryLength - (productKeyLen - 1)); j++) {
            if ((!data.categories[j].onlyShowWhenSpecified || data.categories[i].onlyShowWhenSpecified == 'undefined')) {
                newTd = $("<td>");
                newTr.append(newTd);
            }
        }
    }

    return newTr;
}
var globVar = "";
